<template>
<div>
  <div v-if="showLoading" class="text-center" style="padding: 24px">
    <CSpinner/>
  </div>

  <div v-if="!showLoading">
    <CRow style="margin-bottom:20px">
      <CCol>
        <CButton
          color="success"
          variant="outline"
          square
          size="sm"
          @click="onClickNewPeriod()">
          Periode baru
        </CButton>
      </CCol>
    </CRow>
    <CRow>
      <CDataTable
        :items="items"
        :fields="fields"
        :items-per-page="30"
        hover
        pagination>
        <template #show_edit="{item, index}">
          <td class="py-2">
            <CButton
              color="primary"
              variant="outline"
              square
              size="sm"
              @click="onClickEdit(item, index)">
              Edit
            </CButton>
          </td>
        </template>
        <template #show_delete="{item, index}">
          <td class="py-2">
            <CButton
              color="danger"
              variant="outline"
              square
              size="sm"
              @click="onClickDelete(item, index)">
              Delete
            </CButton>
          </td>
        </template>      
      </CDataTable>
    </CRow>
  </div>

  <CModal :show.sync="showDialog" :title="title" hide-footer>
    <div style="padding: 10px">
      <CInput
        label="Nama periode"
        v-model="record.name"
        horizontal
      />
      <CRow>
        <CCol>
          <div style="padding: 10px; margin-top: 8px">
            <label for="datepicker">Dari</label>
            <Datepicker placeholder="Pilih tanggal" v-model="selectedDateFrom" format="yyyy-MM-dd"/>
          </div>
        </CCol>
        <CCol>
          <div style="padding: 10px; margin-top: 8px">
            <label for="datepicker">Sampai</label>
            <Datepicker placeholder="Pilih tanggal" v-model="selectedDateTo" format="yyyy-MM-dd"/>
          </div>
        </CCol>
      </CRow>
    </div>

    <div slot="footer">
      <CButton @click="dialogClose()">Batal</CButton>
      <CButton @click="dialogSave()" color="success">Simpan</CButton>
    </div>
  </CModal>
</div>
</template>

<script>
  import api from "../../api/api"
  import Datepicker from 'vuejs-datepicker';

  const fields = [
    { 
      key: 'name', 
      label: 'Nama periode', 
      _style: 'min-width:100px'
    },
    { 
      key: 'fromFormatted', 
      label: 'Dari', 
      _style: 'min-width:100px'
    },
    { 
      key: 'toFormatted', 
      label: 'Sampai', 
      _style: 'min-width:100px'
    },
    { 
      key: 'show_edit', 
      label: '', 
      _style: 'width:1%'
    },
    { 
      key: 'show_delete', 
      label: '', 
      _style: 'width:1%'
    }
  ]

  export default {
    components: {
      Datepicker
    },

    data () {
      return {
        showLoading: false,
        items: [],
        fields,
        details: [],

        showDialog: false,
        title: '',
        isEdit: true,
        record: '',
        selectedDateFrom: '',
        selectedDateTo: '',
        user: ''
      }
    },

    async created () {
      var idToken = localStorage.getItem('idToken')

      if(idToken===null || idToken===undefined || idToken==='undefined')
        this.$router.replace('/login', () => {})
      else{
        this.user = JSON.parse(localStorage.getItem('user'))
        var utc = new Date().toJSON().slice(0,10).replace(/-/g,'/')
        this.selectedDateFrom = utc
        this.selectedDateTo = utc
        this.listPeriod()
      }
    },

    methods: {
      async listPeriod () {
        this.showLoading = true
        this.items = []
        let apiRes = await api.listPeriod()
        this.showLoading = false

        if (apiRes.result === 'OK') {
          this.items = apiRes.periods
        } 
        else {
          console.log("listPeriod ERR: " + apiRes.err)
          this.$router.replace('/login', () => {})
        }
      },

      onClickEdit (item) {
        this.isEdit = true
        this.title = "Edit periode waktu"
        this.record = item
        this.selectedDateFrom = item.from
        this.selectedDateTo = item.to
        this.showDialog = true
      },

      async onClickDelete (item) {
        this.record = item
        this.$confirm("Yakin untuk menghapus periode waktu ini?").then(() => {
          this.executeDeletePeriod()
        });
      },

      async executeDeletePeriod(){
        this.showLoading = true
        let apiRes = await api.deletePeriod(this.user.id, this.record.id)
        this.showLoading = false

        if (apiRes.result === 'OK') {
          this.$alert("Record sudah dihapus!")
          this.listPeriod()
        } 
        else {
          if(apiRes.err==="USED_IN_BAHAN_PA")
            this.$alert("Tidak bisa menghapus karena periode waktu ini sudah digunakan untuk Bahan Pemuridan")

          else if(apiRes.err==="USED_IN_BAHAN_PSK")
            this.$alert("Tidak bisa menghapus karena periode waktu ini sudah digunakan untuk Jadwal Persekutuan")

          else{
            console.log("deletePeriod ERR: " + apiRes.err)
            this.$router.replace('/login', () => {})
          }
        }      
      },

      dialogClose(){
        this.showDialog = false
      },

      async dialogSave(){
        var dtFrom = Date.parse(this.selectedDateFrom)
        if(isNaN(dtFrom)) dtFrom = this.selectedDateFrom
        dtFrom += (8*3600*1000)

        var dtTo = Date.parse(this.selectedDateTo)
        if(isNaN(dtTo)) dtTo = this.selectedDateTo
        dtTo += (8*3600*1000)

        console.log("=>from: " + dtFrom)
        console.log("=>to: " + dtTo)

        if(this.record.name.trim()==='')
          this.$alert("Masukkan nama periode waktu")
        else if(dtFrom >= dtTo)
          this.$alert("Masukkan tanggal akhir setelah tanggal awal")
        else{
          this.showDialog = false

          if(this.isEdit){
            this.showLoading = true
            let apiRes = await api.updatePeriod(this.user.id, this.record.id, this.record.name, dtFrom, dtTo)
            this.showLoading = false

            if (apiRes.result === 'OK') {
              this.listPeriod()
            } 
            else {
              console.log("updatePeriod ERR: " + apiRes.err)
              this.$router.replace('/login', () => {})
            }
          }
          else{
            this.showLoading = true
            let apiRes = await api.createPeriod(this.user.id, this.record.name, dtFrom, dtTo)
            this.showLoading = false

            if (apiRes.result === 'OK') {
              this.listPeriod()
            } 
            else {
              console.log("createPeriod ERR: " + apiRes.err)
              this.$router.replace('/login', () => {})
            }
          }
        }
      },

      onClickNewPeriod(){
        this.isEdit = false
        this.title = "Buat periode baru"

        var utc = new Date().toJSON().slice(0,10).replace(/-/g,'/')
        this.record = { name:'', fromFormatted: utc, toFormatted: utc}
        this.selectedDateFrom = utc
        this.selectedDateTo = utc

        this.showDialog = true
      },

    },

  }
</script>
